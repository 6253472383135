import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/BlogPage.css'; // Add styles as needed

const BlogPage = () => {
  // Dummy data for the blog posts. You can replace this with dynamic data (e.g., fetched from a database or API).
  const [posts, setPosts] = useState([
    {
      id: 1,
      title: 'Introduction to Embedded Systems',
      description: 'Learn the basics of embedded systems and their applications in the modern world.',
      date: 'January 24, 2025',
      link: '/blog/embedded-systems-introduction',
    },
    {
      id: 2,
      title: 'Getting Started with Arduino',
      description: 'A beginner’s guide to working with Arduino and building your first embedded project.',
      date: 'January 15, 2025',
      link: '/blog/getting-started-with-arduino',
    },
    {
        id: 3,
        title: 'Getting Started with Solar and Inverter',
        description: 'A beginner’s guide to working with Arduino and building your first embedded project.',
        date: 'January 10, 2025',
        link: '/blog/getting-started-with-arduino',
      },

      {
        id: 4,
        title: 'Introduction to Embedded Systems',
        description: 'Learn the basics of embedded systems and their applications in the modern world.',
        date: 'January 24, 2025',
        link: '/blog/embedded-systems-introduction',
      },
      {
        id: 5,
        title: 'Getting Started with Arduino',
        description: 'A beginner’s guide to working with Arduino and building your first embedded project.',
        date: 'January 15, 2025',
        link: '/blog/getting-started-with-arduino',
      },
      {
          id: 6,
          title: 'Getting Started with Solar and Inverter',
          description: 'A beginner’s guide to working with Arduino and building your first embedded project.',
          date: 'January 10, 2025',
          link: '/blog/getting-started-with-arduino',
        },
    // More posts can be added here
  ]);

  useEffect(() => {
    // You can fetch blog posts from an API here if you want to make the list dynamic
  }, []);

  return (
    <div className="blog-container">
      <h1 className="blog-header">Embedded Systems Tutorials</h1>
      <div className="blog-posts">
        {posts.map((post) => (
          <div className="blog-post" key={post.id}>
            <h2 className="post-title">{post.title}</h2>
            <p className="post-description">{post.description}</p>
            <span className="post-date">{post.date}</span>
            <Link to={post.link} className="read-more">
              Read More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
