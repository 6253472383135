import React from "react";
import ShopHeader from "./ShopHeader";
import BLogFooter from "./BlogFooter"

function BlogLayout({ children }) {
  return (
    <>
      <ShopHeader />
      <div>{children}</div>
      <BLogFooter />
    </>
  );
}

export default BlogLayout;
