import React from "react";
import Layout from "../../components/Layouts/Layout";
import "../../styles/HomeStyle.css";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section4 from "./Section4";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Program from "./Program";


const Home = () => {
  return (
    <>
      <Layout>
        <Section1 />
        <Section2 />
        <Section4 />
        <Program/>
        <Section6 />
        <Section7 />
      </Layout>
    </>
  );
};

export default Home;
