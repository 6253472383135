import React from "react";
import BlogLayout from "../../components/Layouts/BlogLayout";
import "../../styles/HomeStyle.css";
import BlogPost from "./BlogPost";





const Blog1 = () => {
  return (
    <>
      <BlogLayout>

        <BlogPost />
      </BlogLayout>
    </>
  );
};

export default Blog1;
