import React from "react";
import ShopLayout from "../../components/Layouts/ShopLayout";
import "../../styles/HomeStyle.css";

import Section2About from "./Section2About";
import Section4 from "./Section4";
import Program from "./Program";
import Team from "./Team";


const About = () => {
  return (
    <>
      <ShopLayout>
      
        <Section2About />
        <Section4 />
        <Program/>
        
        <Team/>
    
      </ShopLayout>
    </>
  );
};

export default About;
