import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import inverter from "../../images/invett.jpeg";
import Solar from "../../images/Sola.jpg";
import SmartHome from "../../images/SMARTH.webp";

// Mock Data Cards
const mockData = [
  
  {
    image: Solar,
    title: "Solar Installation",
    paragraph: `Solar installation is crucial for reducing energy
     bills and carbon footprint. It provides a sustainable, renewable 
     energy source, increases property value, and offers energy 
     independence by harnessing the power of the sun.`,
  },
  {
    image: inverter,
    title: "Inverter Instalation",
    paragraph: `Installing an inverter system is essential for 
    converting solar energy into usable electricity. It ensures
     efficient power supply, enhances energy independence, 
     reduces electricity bills, and supports sustainable living 
     with renewable energy sources.`,
  },
  {
    image: SmartHome,
    title: "Home Automation",
    paragraph: `Home automation enhances convenience and energy 
    efficiency by integrating smart technologies. It allows remote 
    control of lighting, climate, and security systems, improving 
    comfort, saving energy, and adding value to your home.`,
  },
  
  // Add more mock data objects as needed
];

function Section2About() {
  return (
    <>
      <section className="about_section">
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} className="text-center">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>

              <h2 >let's facilitates your transit to a Sustainable and efficient powered home </h2>
              </div>
              <p>
              "Our vision is to empower clients to transition to sustainable and energy-efficient 
              homes by providing expert guidance through personalized
               energy audits, education on renewable benefits, and
                customized plans. We aim to recommend the best solutions, 
                offer financing options, and deliver ongoing support for
                 optimized energy use and cost reduction." </p>
              <Link as={Link}  to="/about" className="btn order_now btn_red">
                Learn more
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about_wrapper">
        <Container>
          <Row className="justify-content-md-center">
            {mockData.map((cardData, index) => (
              <Col md={6} lg={4} className="mb-4 mb-md-0" key={index}>
                <div className="about_box text-center">
                  <div className="about_icon">
                    <img
                      src={cardData.image}
                      className="img-fluid"
                      alt="icon"
                    />
                    <h4>{cardData.title}</h4>
                  <p>{cardData.paragraph}</p>
                  </div>
                  
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Section2About;
