import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import max from "../../images/max.png";
import Sam from "../../images/Sam.png";
import Ben2 from "../../images/Ben2.png";

// Mock Data Cards
const mockData = [
  
  {
    image: Sam,
    title: "Samuel Markus",
    paragraph: `Computer Engineer`,
    paragraph1: `CEO`,
  },
  {
    image: max ,
    title: "J.T Max",
    paragraph: `Computer Scientist`,
    paragraph1: `Technical Adviser`,
  },
  {
    image: Ben2,
    title: " Benjamin Y. Mailafiya",
    paragraph: `Builder`,
    paragraph1: `Technical Adviser`,
  },
  
  // Add more mock data objects as needed
];

function Team() {
  return (
    <>
    
      <section className="about_wrapper">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
      <h2 style={{ textAlign: 'center' }}> Our Team </h2> 
      </div>
        <Container>
          <Row className="justify-content-md-center">
            {mockData.map((cardData, index) => (
              <Col md={6} lg={4} className="mb-4 mb-md-0" key={index}>
                <div className="about_box text-center">
                  <div className="about_icon">
                    <img
                      src={cardData.image}
                      className="img-fluid"
                      alt="icon"
                    />
                    <h4>{cardData.title}</h4>
                  <p>{cardData.paragraph}</p>
                  <p>{cardData.paragraph1}</p>
                  </div>
                  
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Team;
