import React from "react";
import BlogLayout from "../../components/Layouts/BlogLayout";
import "../../styles/HomeStyle.css";
import BlogPage from "./BlogPage";





const Blog = () => {
  return (
    <>
      <BlogLayout>
      
        <BlogPage />
     </BlogLayout>
    </>
  );
};

export default Blog;
