import React from 'react';

import { useParams } from 'react-router-dom';
import '../../styles/BlogPost.css'; // Add styles as needed

const BlogPost = () => {
  const { postId } = useParams(); // Get the post ID from the URL
  // Example data - replace with dynamic data or a real database fetch
  const posts = {
    'embedded-systems-introduction': {
      title: 'Introduction to Embedded Systems',
      content: `
        <h2>What is an Embedded System?</h2>
        <p>Embedded systems are specialized computing systems that are dedicated to specific tasks...</p>
        <h3>Applications of Embedded Systems</h3>
        <p>Embedded systems are used in a variety of applications, from consumer electronics to industrial machinery...</p>
      `,
      date: 'January 24, 2025',
    },
    'getting started with renewable energy': {
        title: 'Introduction to Solar and Inverter',
        content: `
          <h2>What is an Embedded System?</h2>
          <p>Embedded systems are specialized computing systems that are dedicated to specific tasks...</p>
          <h3>Applications of Embedded Systems</h3>
          <p>Embedded systems are used in a variety of applications, from consumer electronics to industrial machinery...</p>
        `,
        date: 'January 24, 2025',
      },
    'getting-started-with-arduino': {
      title: 'Getting Started with Arduino',
      content: `
        <h2>What is Arduino?</h2>
        <p>Arduino is an open-source electronics platform based on simple software and hardware...</p>
        <h3>Setting Up Your First Arduino Project</h3>
        <p>In this tutorial, we will walk you through how to get your first Arduino project up and running...</p>
      `,
      date: 'January 15, 2025',
    },
    // Add more posts as needed
  };

  const post = posts[postId];

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div className="blog-post-detail">
      <h1 className="post-title">{post.title}</h1>
      <span className="post-date">{post.date}</span>
      <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }}></div>
    </div>
  );
};

export default BlogPost;
