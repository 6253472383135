import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Burger from "../../images/ai.png";
import { Link } from "react-router-dom";

const Section1 = () => {
  const [isHidden, setHidden] = useState(true);
  return (
    <section className="hero_section">
      <Container>
        <Row>
      
          <Col lg={5}>
            <div className="hero_text text-center">
              <h1 className="text-white">Sustainable</h1>
              <h1 className="text-white">Efficient</h1>
              <h2 className="text-white">Energy</h2>
              <p className="text-white pt-2 pb-4" >
            
              "Visit our store for the latest price updates 
              and exclusive offers. <br/>Our team is ready 
              to assist you!"
              </p>
              <Link to="/shop" className="btn order_now">
                visit store
              </Link>
            </div>
          </Col>
          <Col lg={7} className="mb-5 mb-lg-0">
            <div className="position-relative">
             <img hidden={isHidden}  src={Burger} className="img-fluid" alt="Hero" /> 
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section1;
