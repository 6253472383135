import React from "react";
import ShopLayout from "../../components/Layouts/ShopLayout";
import "../../styles/HomeStyle.css";

import Section7 from "./Section7";


const Contact = () => {
  return (
    <>
      <ShopLayout>
        <Section7 />
      </ShopLayout>
    </>
  );
};

export default Contact;
